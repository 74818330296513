
import "./Service.css";
import stand from "./image/development-4536630_1920.png";
import services from "./image/servicepage (1).png";
import appimg from "./image/android-mobile-app-development-7815022_1920.png";
import softwareimg from "./image/software.jpg";
import bussinesimg from "./image/business-1296337_1280.webp";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Service = () => {

    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
    
    return (
        <>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            ></link>
            <img className='serviceimg' src={services} alt='...' />
            <div className="main-section" >
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-4" >
                            <div className="service" >
                                <img className="img-fluid" alt='...' src={stand} />
                            </div>
                        </div>
                        <div className="col-lg-8" >
                            <div className="Service_left">
                                <h2>Web development</h2>
                                We offer comprehensive web development services to help businesses establish a strong online presence, engage their audience, and achieve their digital goals. With our team of experienced developers and designers, we deliver customized solutions tailored to meet the unique needs of each client. In this designing process, expert web designers intricate the best possible user experience. They create a functional interface that grabs attention and helps users to access the online portal with ease. Without an interactive interface and enhanced user experience, a website will fail to attract online traffic and drive engagement. our in-house designers offer end-to-end website designing services, from identifying the scope or the main focus of the business website to sketches, all the way to mock-ups, wireframes, and ultimately the final design.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="main-section" >
                <div className="container">
                    <div className="row" >

                        <div className="col-lg-8" >
                            <div className="Service_left">
                                <h2>App development</h2>
                                <p> We believe in a collaborative approach to app development, working closely with our clients to understand their vision, target audience, and business objectives. Our process begins with in-depth research and analysis, followed by strategic planning, design, development, and testing to ensure a seamless and successful launch.We develop native mobile applications for iOS and Android platforms, leveraging the unique features and capabilities of each platform to deliver optimal performance and user experience.</p>
                                <p>Our cross-platform development expertise allows us to build apps that run smoothly across multiple platforms using frameworks like React Native, Flutter, or Xamarin, saving time and resources without compromising quality. Whether you need a simple utility app or a complex enterprise solution, we have the expertise to create custom applications tailored to your specific requirements and business needs.</p>
                            </div>
                        </div>
                        <div className="col-lg-4" >
                            <div className="service" >
                                <img className="img-fluid" alt='...' src={appimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-section" >
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-4" >
                            <div className="service" >
                                <img className="img-fluid" alt='...' src={softwareimg} />
                            </div>
                        </div>
                        <div className="col-lg-8" >
                            <div className="Service_left">
                                <h2>Software development</h2>
                                <p>We develop custom software solutions tailored to your specific needs, whether you require a standalone application, a web-based platform, or a comprehensive enterprise solution. Our expertise spans a wide range of industries and technologies, allowing us to deliver solutions that drive efficiency, productivity, and growth.</p>

                                <p>We specialize in integrating disparate systems and applications to streamline workflows, improve data sharing, and enhance productivity. Our API development services enable seamless communication between different software components, third-party services, and platforms, allowing you to unlock new possibilities and drive innovation</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="main-section" >
                <div className="container">
                    <div className="row" >

                        <div className="col-lg-8" >
                            <div className="Service_left">
                                <h2>Digital marketing</h2>
                                <p>  We specialize in providing comprehensive digital marketing services to help businesses succeed in the ever-evolving online landscape. With our team of experienced marketers, strategists, and creatives, we develop customized strategies that drive results and maximize return on investment (ROI).</p>
                                <p>We take a data-driven approach to digital marketing, leveraging analytics, market research, and industry insights to inform our strategies and tactics. Our goal is to understand your target audience, identify opportunities for growth, and develop strategies that align with your business objectives.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4" >
                            <div className="service" >
                                <img className="img-fluid" alt='...' src={bussinesimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Second-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div class="card_1 ">
                                <div className="row justify-content-center">
                                    <span className="Dimond_icon" ><i class="fa fa-diamond" aria-hidden="true"></i></span>
                                    <h4>Responsive Design</h4>
                                    <p>  we prioritize responsive design in all our web development projects. Our team of experienced designers and developers specializes in creating responsive websites that deliver exceptional user experiences across devices. From fluid layouts to flexible images and optimized user interfaces, we ensure that your website looks and performs its best on every screen.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="card_1 card1">
                                <div className="row justify-content-center">
                                    <span className="Dimond_icon Dimond_icon1" ><i class="fa fa-diamond" aria-hidden="true"></i></span>
                                    <h4>Tranning And Placement</h4>
                                    <p>  we offer comprehensive training and placement services designed to equip individuals with the skills and knowledge needed to excel in their careers and connect them with rewarding employment opportunities. Whether you're a recent graduate, a career changer, or a professional looking to upskill, we're here to support your journey towards success.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="card_1 card3">
                                <div className="row justify-content-center">
                                    <span className="Dimond_icon Dimond_icon3" ><i class="fa fa-diamond" aria-hidden="true"></i></span>
                                    <h4>Data Science</h4>
                                    <p> We take a comprehensive approach to data science, combining advanced analytics, machine learning, and artificial intelligence techniques to extract valuable insights from complex datasets. Our team of data scientists, analysts, and engineers works closely with clients to understand their business objectives, identify key challenges, and develop customized solutions that address their specific needs.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="card_1 card2 pb-10">
                                <div className="row justify-content-center">
                                    <span className="Dimond_icon Dimond_icon2" ><i class="fa fa-diamond" aria-hidden="true"></i></span>
                                    <h4>Online cources</h4>
                                    <p>we offer a wide range of online courses designed to help you acquire new skills, advance your career, and achieve your personal and professional goals. Whether you're looking to learn a new language, master a technical skill, or explore a new hobby, our courses provide flexible and accessible learning opportunities tailored to your needs. Regularly update your course based on feedback</p>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export default Service;