import "./Homepage.css";
import Enqiry from "./Enqiry";
import Aboutussec  from "./image/contact us.png";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function Contact(){
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
    
    return(
 
     <>
     <div className="about-img">
      <img src={Aboutussec} className="img-fluid" alt="..."/>
              </div>
     <Enqiry/>
  
     </>
    )
 
 }
 export default Contact